import React from 'react'
import Avatar from '../assets/avatar.jpg'
import './NameCard.scss'

const NameCard = () => (
    <div className="name-card" data-testid="name-card">
        <div className="name-card__avatar">
            <img src={Avatar} alt="Avatar"/>
        </div>
        <div className="name-card__content">
            <div className="content__name">Gordon Krull</div>
            <div className="content__title">Software Engineer</div>
        </div>
    </div>
)

export default NameCard