import React from 'react'
import './App.scss'
import NameCard from './NameCard/NameCard'

const App = () => {
    return (
        <div className="app">
            <NameCard/>
        </div>
    )
}

export default App
